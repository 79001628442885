import React, { useState } from 'react';
import { filteredKundeliste } from './kundeHelper';
import KundeoversiktFilter from './Kundeoversikt/KundeoversiktFilter';
import { IKunde, IRadgiver } from '../shared/lib/types';
import CheckboxInput from '../../form/CheckboxInput';
import { InputElementEnum } from '../../../shared/lib/types';

export interface IVelgKundeliste {
    onKundeSelected(kunder: Array<string>): void;
    selectMultiple?: boolean;
    selectedKunder?: Array<string>;
    kundeliste: Array<IKunde>;
    kundeansvarlige?: Array<IRadgiver>;
}

const VelgKundeListe = (props: IVelgKundeliste) => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedKundeansvarligBrukerId, setSelectedKundeansvarligBrukerId] = useState<string>('');

    function handleCheckboxChange(kundeId: string, selected: boolean) {
        let selectedKunder = [...props.selectedKunder];
        if (!selected && selectedKunder.includes(kundeId)) {
            selectedKunder.splice(selectedKunder.indexOf(kundeId), 1);
        } else {
            selectedKunder.push(kundeId);
        }
        props.onKundeSelected(selectedKunder);
    }

    return (
        <div>
            <KundeoversiktFilter
                searchQuery={searchQuery}
                selectedKundeansvarligBrukerId={selectedKundeansvarligBrukerId}
                kundeansvarlige={props.kundeansvarlige ?? null}
                showAddKundeBtn={false}
                onSearchQueryChange={setSearchQuery}
                onSelectedKundeansvarligChange={setSelectedKundeansvarligBrukerId}
            />

            <div className="kundeliste">
                <table className="table--kundeliste">
                    <thead>
                        <tr>
                            {props.selectMultiple ? (
                                <td
                                    onClick={() => {
                                        if (props.selectedKunder.length > 0) {
                                            props.onKundeSelected([]);
                                            return;
                                        }
                                        const allKunder = [...filteredKundeliste(props.kundeliste, selectedKundeansvarligBrukerId, searchQuery)];
                                        props.onKundeSelected(allKunder.map((item) => item.id));
                                    }}
                                    className="table--choose__all"
                                >
                                    {props.selectedKunder.length > 0 ? 'Fjern alle' : 'Velg alle'}
                                </td>
                            ) : null}
                            <td>Kundenavn</td>
                            <td>Org.nummer</td>
                            <td>Kundeansvarlig</td>
                            <td>
                                <span className="sr-only">Velg</span>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {props.kundeliste &&
                            filteredKundeliste(props.kundeliste, selectedKundeansvarligBrukerId, searchQuery).map((kunde, i) => {
                                return (
                                    <tr key={kunde.organisasjonsnummer} className={`${kunde.egetForetak ? 'tr-border' : ''}`} >
                                        {props.selectMultiple ? (
                                            <td>
                                                <div className="table__checkbox">
                                                    <CheckboxInput
                                                        focus=""
                                                        onInputChange={(name, value) => {
                                                            handleCheckboxChange(kunde.id, value);
                                                        }}
                                                        checked={props.selectedKunder?.includes(kunde.id) ?? false}
                                                        index={i}
                                                        label=""
                                                        name={`check_kunde[${i}]`}
                                                        type={InputElementEnum.checkbox}
                                                        value={kunde.id}
                                                    />
                                                </div>
                                            </td>
                                        ) : null}
                                        <td className="table--cell">
                                            <span className={`icon ${kunde.egetForetak ? 'icon-eget-regnskapsforetak' : 'icon-kundenavn-stor'}`} />
                                            <span className="table-cell__title">
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: `${kunde.navn}${kunde.egetForetak ? ' (Eget foretak)' : ''}`,
                                                    }}
                                                ></span>
                                                {kunde.harBegrensetKundesynlighet && <span className="icon icon-lock-open" />}
                                            </span>
                                        </td>
                                        <td
                                            dangerouslySetInnerHTML={{
                                                __html: kunde.organisasjonsnummer,
                                            }}
                                        ></td>
                                        <td
                                            dangerouslySetInnerHTML={{
                                                __html: `${kunde.kundeansvarlig.fornavn} ${kunde.kundeansvarlig.etternavn}`,
                                            }}
                                        ></td>
                                        <td>
                                            <div className="table__actions">
                                                {!props.selectMultiple && !props.selectedKunder ? (
                                                    <button
                                                        className="btn btn--secondary btn--sm"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            props.onKundeSelected([kunde.id]);
                                                        }}
                                                    >
                                                        Velg
                                                    </button>
                                                ) : null}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default VelgKundeListe;
