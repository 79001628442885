interface IErrorComponent {
    serverErrorMessage: Array<string>;
}
const ErrorComponent = (props: IErrorComponent) => (
    <div className="container">
        <div className="form__errors">
            <h4>Kunne ikke lagre</h4>
            <ul>
                {props.serverErrorMessage.map((error, i) => {
                    return <li key={error + i}>{error}</li>;
                })}
            </ul>
        </div>
    </div>
);

export default ErrorComponent;